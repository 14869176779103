
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import TaskList from "./components/TaskList.vue";
import { Pagination } from "ant-design-vue";
@Component({
  name: "Task",
  components: { TaskList, [Pagination.name]: Pagination },
  filters: {
    //局部过滤器
  },
})
export default class Task extends Vue {
  public taskList: any[] = [];
  public taskType: any[] = [
    {
      id: "",
      name: "全部任务",
    },
  ];
  public taskTypeId: string = "";
  public total: number = 0;
  public current: number = 1;

  queryTaskList() {
    this.$apis
      .taskList({
        isPage: 1,
        pageNum: this.current,
        pageSize: 5,
        taskTypeId: this.taskTypeId,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.taskList = res.data.list;
          this.total = res.data.total;
          this.current = res.data.pageNum;
        }
      });
  }

  queryTaskType() {
    this.$apis.taskTypeList().then((res: any) => {
      if (res.code === "200") {
        this.taskType = [
          {
            id: "",
            name: "全部任务",
          },
          ...res.data.list,
        ];
      }
    });
  }

  handleChange(page: number) {
    this.current = page;
    this.queryTaskList();
  }

  selectChange() {
    this.queryTaskList();
  }
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数
  created(): void {
    this.queryTaskType();
    this.queryTaskList();
  }
}
